import { Language } from "./Pattern";

export const dictionary: Readonly<Language> = {
    meta: {
        displayName: 'Português'
    },
    header: {
        menus: {
            article: 'Artigo',
            home: 'Inicio',
            system: 'Sistema'
        },
        title: 'SEPAREH'
    },    
    tipBox: {
        buttonName: 'Iniciar',
        description: 'Você pode usar o sistema especialista para prever reações em um paciente com a doença clicando no botão abaixo.'
    },
    home: {
        content: `Bem-vindo ao SEPAREH, um sistema de suporte à decisão que calcula o risco de um paciente afetado por hanseníase desenvolver um dos dois estados reacionais associados à doença: reação do tipo 1 (T1R, ou reação reversa – RR) e reação do tipo 2 (T2R, ou eritema nodoso hansênico – ENH ).

        O SEPAREH foi desenvolvido através de ferramentas de mineração de dados aplicadas a um conjunto de informações clínicas, sócio-demográficas e moleculares de quatro amostras populacionais brasileiras totalizando 1450 pacientes. O sistema é capaz de calcular o risco de um paciente com hanseníase desenvolver T1R ou T2R com base nos dados fornecidos pelo operador, completos ou parciais – ou seja, não é necessário ter todas as informações listadas na interface, em especial, as informações genético-moleculares que sabemos que não são comumente produzidas pelos centros de atendimento primário à saúde: simplesmente entre com os dados disponíveis do paciente e você verá o risco de ocorrência de T1R e T2R sendo calculado em tempo real. Quanto mais informações são fornecidas, maior a sensibilidade e a especificidade do sistema, que podem chegar a 85,7% e 89,4%, respectivamente. Para maiores detalhes, por favor acesse o artigo científico que descreve o desenvolvimento do SEPAREH, disponível em ___.
        
        O uso do SEPAREH é gratuito e estudos adicionais de replicação/validação utilizando a plataforma são encorajados e muito bem-vindos. Caso considere que podemos ajudar, por favor não hesite em entrar em contato pelo email m.mira@pucpr.br.
        
        Sucesso!
        `,
        title: 'Sistema Especialista para Hanseníase'
    },
    article: {
        content: 'O artigo referente ao SEPAREH foi publicado na Frontiers of Medicine, na seção de Infectious Diseases: Pathogenesis ans Therapy no dia 26 de julho de 2023. O acesso ao artigo pode ser feito através do seguinte endereço: https://www.frontiersin.org/articles/10.3389/fmed.2023.1233220/full',
        title: 'Publicação'
    },
    system: {
        topBar: {
            database: {
                label: 'Base de dados',
                options: [
                    {id: 'all', value: 'Completa'},
                    {id: 'bauru', value: 'Bauru'},
                    {id: 'fortaleza', value: 'Fortaleza'},
                    {id: 'goiania', value: 'Goiânia'},
                    {id: 'manaus', value: 'Manaus'}
                ]
            },
            clearButton: 'Limpar',
            rememberInformation: 'Lembrar informações'
        },
        questionGroups: [
            {
                title: 'Dados Sócio-demograficos',
                questions: [
                    {
                        id: 0,
                        title: 'Sexo',
                        options: [
                            'Masculino',
                            'Feminino'
                        ]
                    },
                    {
                        id: 1,
                        title: 'Faixa etária',
                        options: [
                            '0 a 19 anos',
                            '20 a 39 anos',
                            '40 a 64 anos',
                            'Idoso'
                        ]
                    },
                    {
                        id: 5,
                        title: 'Etnia',
                        options: [
                            'Branca',
                            'Preta',
                            'Parda',
                            'Amarela'
                        ]
                    }
                ]
            },
            {
                title: 'Dados Clínicos',
                questions: [
                    {
                        id: 9,
                        title: 'Número de lesões',
                        options: [
                            '1 a 5',
                            '6 a 11',
                            'Infiltração difusa',
                        ]
                    },
                    {
                        id: 10,
                        title: 'Tipo de lesão',
                        options: [
                            'Placa',
                            'Pápula',
                            'Mácula',
                            'Nódulo',
                            'Infiltração difusa'
                        ]
                    },
                    {
                        id: 11,
                        title: 'Cor da lesão',
                        options: [
                            'Normocrômica',
                            'Hipocrômica',
                            'Hipercrômica',
                            'Eritematosa'
                        ]
                    },
                    {
                        id: 12,
                        title: 'Sensibilidade',
                        options: [
                            'Normal',
                            'Alterada',
                            'Duvidosa',
                        ]
                    },
                    {
                        id: 4,
                        title: 'Forma clínica',
                        options: [
                            'TT',
                            'BT',
                            'BB',
                            'BL',
                            'LL',
                            'Indeterminada',
                        ]
                    },
                    {
                        id: 3,
                        title: 'Tratamento',
                        options: [
                            '6 meses',
                            '12 meses'
                        ]
                    },
                    {
                        id: 2,
                        title: 'Tempo do primeiro sintoma (auto-relato)',
                        options: [
                            '0 a 1 ano',
                            '1 a 2 anos',
                            '2 a 3 anos',
                            '3+ anos'
                        ]
                    }
                ]
            },
            {
                title: 'Dados Laboratoriais',
                questions: [
                    {
                        id: 13,
                        title: 'Índice baciloscópico',
                        options: [
                            'Negativo',
                            '1+',
                            '2+',
                            '3+',
                            '4+',
                            '5+',
                            '6+'
                        ]
                    },
                    {
                        id: 14,
                        title: 'Índice histológico',
                        options: [
                            'Negativo',
                            '1+',
                            '2+',
                            '3+',
                            '4+',
                            '5+',
                            '6+'
                        ]
                    },
                    {
                        id: 15,
                        title: 'Intensidade de PGL-1',
                        options: [
                            'Negativo',
                            '1+',
                            '2+',
                            '3+',
                            '4+'
                        ]
                    },
                ]
            },
            {
                title: 'Histórico Familiar',
                questions: [
                    {
                        id: 6,
                        title: 'Primeiro grau',
                        options: [
                            'Sim',
                            'Não'
                        ]
                    },
                    {
                        id: 7,
                        title: 'Segundo grau',
                        options: [
                            'Sim',
                            'Não'
                        ]
                    },
                    {
                        id: 8,
                        title: 'Contato',
                        options: [
                            'Sim',
                            'Não'
                        ]
                    }
                ]
            },
            {
                title: 'Dados Genéticos',
                questions: [
                    {
                        id: 16,
                        title: 'IL6 - rs2069832',
                        options: [
                            'GG',
                            'AA+AG'
                        ]
                    },
					{
                        id: 17,
                        title: 'IL6 - rs2069840',
                        options: [
                            'CC',
                            'CC+CG'
                        ]
                    },
					{
                        id: 18,
                        title: 'IL6 - rs2069845',
                        options: [
                            'AA',
                            'GG+AG'
                        ]
                    },
					{
                        id: 19,
                        title: 'IL6 - rs1800795',
                        options: [
                            'GG',
                            'CC+CG'
                        ]
                    },
					{
                        id: 21,
                        title: 'TLR1 - rs5743618',
                        options: [
                            'GG',
                            'GT',
                            'TT'
                        ]
                    },
					{
                        id: 22,
                        title: 'TLR1 - rs4833095',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 20,
                        title: 'NOD2 - rs8057341',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
						disable: true,
                        id: 23,
                        title: 'Gene Desconhecido rs4909863',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
						disable: true,
                        id: 24,
                        title: 'Gene Desconhecido rs4130173',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 25,
                        title: 'TNFSF8 - rs6478108',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 26,
                        title: 'TNFSF8 - rs7863183',
                        options: [
                            'CC',
                            'CT',
                            'TT'
                        ]
                    },
					{
                        id: 27,
                        title: 'TNFSF8 - rs1555457',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 28,
                        title: 'TNFSF8 - rs3181348',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 29,
                        title: 'ENSG00000235140 - rs7090170',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 30,
                        title: 'ENSG00000235140 - rs10826321',
                        options: [
                            'CT',
                            'TT'
                        ]
                    },
					{
                        id: 31,
                        title: 'ENSG00000235140 - rs1875147',
                        options: [
                            'CC',
                            'CT',
                            'TT'
                        ]
                    },
					{
                        id: 32,
                        title: 'ENSG00000235140 - rs7916086',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 33,
                        title: 'LRRK2 - rs4768236',
                        options: [
                            'AA',
                            'CC'
                        ]
                    },
					{
                        id: 34,
                        title: 'LRRK2 - rs3761863',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 35,
                        title: 'LRRK2 - rs3886747',
                        options: [
                            'CC',
                            'CT',
                            'TT'
                        ]
                    },
					{
						disable: true,
                        id: 36,
                        title: 'PTPN1 - rs6020566',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
						disable: true,
                        id: 37,
                        title: 'PTPN1 - rs6067472',
                        options: [
                            'AA',
                            'AT',
                            'TT'
                        ]
                    }
                ]
            }
        ],
        reults: {
            label: 'Resultados',
            noReaction: {
                name: 'Sem reação',
                description: 'Nenhuma reação é esperada.'
            },
            type1: {
                name: 'Reação Tipo 1',
                description: 'Manifestações clínicas da T1R são infiltrações de lesões antigas associadas ao surgimento de novas lesões, com formas de manchas ou placas infiltradas, eritema, dor e/ou espessamento de nervos periféricos com perda da função sensitivo-motora.'
            },
            type2: {
                name: 'Reação Tipo 2',
                description: 'A RT2 é uma reação inflamatória aguda, sistêmica, que possivelmente envolve a formação e depósitos de imunocomplexos que circulam pelo sangue periférico (reação do tipo III e Gell & Coombs). É caracterizada pelo surgimento de lesões eritematosas generalizadas, nódulos e pápulas acompanhadas de febre.'
            }
        }
    },
    footer: {
        content: 'Sistema Especialista Para Avaliação de Risco de Estado Reacional em Hanseníase - 2021'
    },
    logs: {
        loadingAlt: 'Carregando...'
    },
    error: {
        badResult: {
            title: 'Erro: Não foi possível realizar o diagnóstico!',
            description: 'Reinicie a página e se o erro persistir contate o desenvolvedor.'
        }
    }
}