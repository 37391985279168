import { Language } from "./Pattern";

export const dictionary: Readonly<Language> = {
    meta: {
        displayName: 'English'
    },
    header: {
        menus: {
            article: 'Article',
            home: 'Home',
            system: 'System'
        },
        title: 'SEPAREH'
    },    
    tipBox: {
        buttonName: 'Start',
        description: 'You can use the expert system for predict reaction in a patient with the disease clicking the button below.'
    },
    home: {
        content: `Welcome to SEPAREH, a decision support system that calculates the risk of a leprosy-affected patient developing one of the two reaction states associated with the disease: type 1 reaction (T1R, or reversal reaction) and type 2 reaction (T2R, or erythema nodosum leprosum – ENL).

        SEPAREH (from Portuguese, “Sistema Especialista Para Avaliação de Risco de Estado Reacional em Hanseníase”) was developed using data mining tools applied to a set of clinical information, socio-demographic and molecular information from four Brazilian population samples totaling 1450 patients. The system is able to calculate the risk of a leprosy patient developing T1R or T2R based on data provided by the operator, complete or partial - that is, it is not necessary to have all the information listed on the interface, in particular, the genetic information that we know are not commonly produced by primary health care centers: simply enter available patient data and you will see the risk of occurrence of T1R and T2R being calculated in real time. The more information is provided, the greater the sensitivity and specificity of the system, which can reach 85.7% and 89.4%, respectively. For more details, please access the scientific article that describes the development of SEPAREH, available at ___.
        
        The use of SEPAREH is free and additional replication/validation studies using the platform are encouraged and very welcome. If you think we can help, please do not hesitate to contact us by email at m.mira@pucpr.br. 
        
        Success!`,
        title: 'Leprosy Specialist System'
    },
    article: {
        content: 'The article regarding SEPAREH was published in Frontiers of Medicine, in the Infectious Diseases: Pathogenesis and Therapy section on July 26, 2023. Access to the article can be obtained through the following link: https://www.frontiersin.org/articles/10.3389/fmed.2023.1233220/full',
        title: 'Publication'
    },
    system: {
        topBar: {
            database: {
                label: 'Database',
                options: [
                    {id: 'all', value: 'All'},
                    {id: 'bauru', value: 'Bauru'},
                    {id: 'fortaleza', value: 'Fortaleza'},
                    {id: 'goiania', value: 'Goiânia'},
                    {id: 'manaus', value: 'Manaus'}
                ]
            },
            clearButton: 'Clear',
            rememberInformation: 'Remember information'
        },
        questionGroups: [
            {
                title: 'Sociodemographic Data',
                questions: [
                    {
                        id: 0,
                        title: 'Gender',
                        options: [
                            'Male',
                            'Female'
                        ]
                    },
                    {
                        id: 1,
                        title: 'Age group',
                        options: [
                            '0 to 19 yars',
                            '20 to 39 yars',
                            '40 to 64 yars',
                            'Elderly',
                        ]
                    },
                    {
                        id: 5,
                        title: 'Ethnicity',
                        options: [
                            'White',
                            'Black',
                            'Mixed race',
                            'Yellow',
                        ]
                    }
                ]
            },
            {
                title: 'Clinical Data',
                questions: [
                    {
                        id: 9,
                        title: 'Number of lesion',
                        options: [
                            '1 to 5',
                            '6 to 11',
                            'Diffuse Infiltration',
                        ]
                    },
                    {
                        id: 10,
                        title: 'Type of lesion',
                        options: [
                            'Plaques',
                            'Papules',
                            'Macules',
                            'Nodules',
                            'Diffuse Infiltration'
                        ]
                    },
                    {
                        id: 11,
                        title: 'Color of lesion',
                        options: [
                            'Normocrhomic',
                            'Hypochromic',
                            'Hyperchromic',
                            'Erythematous',
                        ]
                    },
                    {
                        id: 12,
                        title: 'Sensibility',
                        options: [
                            'Normal',
                            'Impairment',
                            'Dubious',
                        ]
                    },
                    {
                        id: 4,
                        title: 'Ridley-Jopling Classification',
                        options: [
                            'TT',
                            'BT',
                            'BB',
                            'BL',
                            'LL',
                            'Indeterminate',
                        ]
                    },
                    {
                        id: 3,
                        title: 'Multidrug Therapy',
                        options: [
                            '6 months',
                            '12 months',
                        ]
                    },
                    {
                        id: 2,
                        title: 'First Signs and Symptoms (self report)',
                        options: [
                            '0 to 1 year',
                            '1 to 2 years',
                            '2 to 3 years',
                            '3+ years',
                        ]
                    }
                ]
            },
            {
                title: 'Laboratory Data',
                questions: [
                    {
                        id: 13,
                        title: 'Bacilloscopic Index',
                        options: [
                            'Negative',
                            '1+',
                            '2+',
                            '3+',
                            '4+',
                            '5+',
                            '6+',
                        ]
                    },
                    {
                        id: 14,
                        title: 'Histological Index',
                        options: [
                            'Negative',
                            '1+',
                            '2+',
                            '3+',
                            '4+',
                            '5+',
                            '6+',
                        ]
                    },
                    {
                        id: 15,
                        title: 'PGL-1 intensity',
                        options: [
                            'Negative',
                            '1+',
                            '2+',
                            '3+',
                            '4+',
                        ]
                    },
                ]
            },
            {
                title: 'Family history',
                questions: [
                    {
                        id: 6,
                        title: 'First Degree',
                        options: [
                            'Yes',
                            'No',
                        ]
                    },
                    {
                        id: 7,
                        title: 'Second Degree',
                        options: [
                            'Yes',
                            'No',
                        ]
                    },
                    {
                        id: 8,
                        title: 'Contact',
                        options: [
                            'Yes',
                            'No',
                        ]
                    }
                ]
            },
            {
                title: 'Genetic Data',
                questions: [
                    {
                        id: 16,
                        title: 'IL6 - rs2069832',
                        options: [
                            'GG',
                            'AA+AG'
                        ]
                    },
					{
                        id: 17,
                        title: 'IL6 - rs2069840',
                        options: [
                            'CC',
                            'CC+CG'
                        ]
                    },
					{
                        id: 18,
                        title: 'IL6 - rs2069845',
                        options: [
                            'AA',
                            'GG+AG'
                        ]
                    },
					{
                        id: 19,
                        title: 'IL6 - rs1800795',
                        options: [
                            'GG',
                            'CC+CG'
                        ]
                    },
					{
                        id: 21,
                        title: 'TLR1 - rs5743618',
                        options: [
                            'GG',
                            'GT',
                            'TT'
                        ]
                    },
					{
                        id: 22,
                        title: 'TLR1 - rs4833095',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 20,
                        title: 'NOD2 - rs8057341',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
						disable: true,
                        id: 23,
                        title: 'Unknown Gene rs4909863',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
						disable: true,
                        id: 24,
                        title: 'Unknown Gene rs4130173',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 25,
                        title: 'TNFSF8 - rs6478108',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 26,
                        title: 'TNFSF8 - rs7863183',
                        options: [
                            'CC',
                            'CT',
                            'TT'
                        ]
                    },
					{
                        id: 27,
                        title: 'TNFSF8 - rs1555457',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 28,
                        title: 'TNFSF8 - rs3181348',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 29,
                        title: 'ENSG00000235140 - rs7090170',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 30,
                        title: 'ENSG00000235140 - rs10826321',
                        options: [
                            'CT',
                            'TT'
                        ]
                    },
					{
                        id: 31,
                        title: 'ENSG00000235140 - rs1875147',
                        options: [
                            'CC',
                            'CT',
                            'TT'
                        ]
                    },
					{
                        id: 32,
                        title: 'ENSG00000235140 - rs7916086',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 33,
                        title: 'LRRK2 - rs4768236',
                        options: [
                            'AA',
                            'CC'
                        ]
                    },
					{
                        id: 34,
                        title: 'LRRK2 - rs3761863',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
                        id: 35,
                        title: 'LRRK2 - rs3886747',
                        options: [
                            'CC',
                            'CT',
                            'TT'
                        ]
                    },
					{
						disable: true,
                        id: 36,
                        title: 'PTPN1 - rs6020566',
                        options: [
                            'AA',
                            'AG',
                            'GG'
                        ]
                    },
					{
						disable: true,
                        id: 37,
                        title: 'PTPN1 - rs6067472',
                        options: [
                            'AA',
                            'AT',
                            'TT'
                        ]
                    }
                ]
            }
        ],
        reults: {
            label: 'Results',
            noReaction: {
                name: 'No reaction',
                description: 'No reaction is expected.'
            },
            type1: {
                name: 'Type 1 reaction',
                description: 'Clinical manifestations of T1R include infiltrations of old lesions associated with the appearance of new lesions with infiltrated spots or plaques, erythema, pain and/or thickening of peripheral nerves with loss of sensory-motor function.'
            },
            type2: {
                name: 'Type 2 reaction',
                description: 'RT2 is an acute, systemic inflammatory reaction, which possibly involves the formation and deposits of immune complexes that circulate in the peripheral blood (type III and Gell & Coombs reaction). It is characterized by the appearance of generalized erythematous lesions, nodules and papules accompanied by fever.',
            }
        }
    },
    footer: {
        content: 'Expert System for Risk Prediction of Reactional States in Leprosy - 2021'
    },
    logs: {
        loadingAlt: 'Loading...'
    },
    error: {
        badResult: {
            title: 'Error: Could not perform the diagnosis!',
            description: 'Restart the page and if the error persists contact the developer.'
        }
    }
}